import once from 'lodash/once';
import { SelectProps } from 'tdesign-react';

interface Item<T> {
  label: string;
  value: T;
  theme?: 'default' | 'primary' | 'warning' | 'danger' | 'success';
}

export class Enum<T extends { [alias: string]: Item<any> }, V = T[keyof T]['value']> {
  public enums: T;

  constructor(enums: T) {
    this.enums = enums;
  }

  public items = once(() => Object.entries(this.enums).map(([alias, item]) => ({
    alias,
    ...item,
  })));

  public alias2Value(alias: keyof typeof this.enums): V {
    return this.enums[alias]?.value;
  }

  public alias2Label(alias: keyof typeof this.enums) {
    return this.enums[alias]?.label;
  }

  public alias2Theme(alias: keyof typeof this.enums) {
    return this.enums[alias]?.theme;
  }

  public value2Label(value: V): string | undefined {
    return this.items().find(item => item.value === value)?.label;
  }

  public value2Theme(value: V): 'default' | 'primary' | 'warning' | 'danger' | 'success' {
    return this.items().find(item => item.value === value)?.theme || 'default';
  }
}

declare global {
  type EnumValueType<T extends InstanceType<typeof Enum>, Value = T['enums'][keyof T['enums']]['value']> = Value;
}

// demo

// const TEST = new Enum({
//   aa: { value: 111, label: '文案a' },
//   bb: { value: 222, label: '文案a' },
//   cc: { value: 333, label: '文案a' },
// });

// console.log('======', TEST.items());
// console.log(TEST.alias2Label('aa'));

// const aa: EnumValueType<typeof TEST> = 11; // 推导出typeof aa = number

// 或者如下
// enum TEST_ENUM {
//   aa = 'aa',
//   bb = 'bb',
//   cc = 'cc',
// }

// const TEST = new Enum({
//   aa: { value: TEST_ENUM.aa, label: '文案a' },
//   bb: { value: TEST_ENUM.bb, label: '文案a' },
//   cc: { value: TEST_ENUM.cc, label: '文案a' },
// });
// const a = TEST.alias2Value('aa');

// console.log('======', TEST.items());
// 会产生更强的约束，否则只会推导出"bb"的类型为string
// const bb: EnumValueType<typeof TEST> = 'bb'; //这里会报错，改为TEST_ENUM.xxx
// console.log(bb);

export const enum2Options = (ins: Enum<any>): SelectProps['options'] => ins.items().map(item => ({
  label: item.label,
  value: item.value,
}));

export const enumOptions = (ins: Enum<any>): SelectProps['options'] => {
  const obj: any = {};
  ins.items().forEach((item) => {
    obj[item.value] = item.label;
  });
  return obj;
};
// ins.items().map((item) => ({
//   label: item.label,
//   value: item.value,
// }));
